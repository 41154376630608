<template>
  <form @submit.prevent="submitForm">
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            :label="$t('forms.document.description')"
            v-model="$v.form.description.$model"
            :error-messages="descriptionErrors"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-file-input
            :label="$t('forms.document.file')"
            v-model="$v.form.file.$model"
            :error-messages="fileErrors"
            :show-size="true"
          ></v-file-input>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn type="submit" :color="iconColor(button.icon)" :loading="loading">
          <span v-if="button.text">
            {{ button.text }}
          </span>
          <v-icon v-else>
            {{ button.icon }}
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-container>
  </form>
</template>

<script>
import { required, requiredIf, maxLength } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  name: "DocumentForm",
  props: {
    event: {
      type: Object,
      required: true
    },
    heat: {
      type: Object,
      default: null
    },
    category: {
      type: Object,
      default: null
    },
    document: {
      type: Object,
      default: null
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        category:
          document && document.category
            ? document.category
            : this.category
            ? this.category.value
            : null,
        description:
          document && document.description ? document.description : null,
        file: null
      },
      loading: false,
      button: {
        text: this.$i18n.t("forms.document.submit"),
        icon: null
      },
      allowedFilesTypes: [
        "text/plain",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "application/vnd.oasis.opendocument.text",
        "application/vnd.oasis.opendocument.spreadsheet"
      ]
    };
  },
  validations: {
    form: {
      category: { required },
      description: {
        required,
        maxLength: maxLength(250)
      },
      file: {
        required: requiredIf(function() {
          return !this.editing;
        }),
        fileTypes: function(value) {
          return value ? this.allowedFilesTypes.includes(value.type) : true;
        },
        maxFileSize: value =>
          value && value.size ? value.size <= 5000000 : true
      }
    }
  },
  computed: {
    categoryErrors() {
      const errors = [];
      if (!this.$v.form.category.$dirty) return errors;
      !this.$v.form.category.required &&
        errors.push(this.$i18n.t("forms.document.errors.category.required"));
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.form.description.$dirty) return errors;
      !this.$v.form.description.required &&
        errors.push(this.$i18n.t("forms.document.errors.description.required"));
      !this.$v.form.description.maxLength &&
        errors.push(
          this.$i18n.t("forms.document.errors.description.maxLength", {
            length: 250
          })
        );
      return errors;
    },
    fileErrors() {
      const errors = [];
      if (!this.$v.form.file.$dirty) return errors;
      !this.$v.form.file.required &&
        errors.push(this.$i18n.t("forms.document.errors.file.required"));
      !this.$v.form.file.fileTypes &&
        errors.push(this.$i18n.t("forms.document.errors.file.fileTypes"));
      !this.$v.form.file.maxFileSize &&
        errors.push(
          this.$i18n.t("forms.document.errors.file.maxFileSize", { number: 5 })
        );
      return errors;
    }
  },
  watch: {
    category(newCategory, oldCategory) {
      if (newCategory) {
        this.form.category = newCategory.value;
      }
    }
  },
  methods: {
    ...mapActions({
      createEventDocument: "events/createEventDocument",
      createHeatDocument: "events/createHeatDocument",
      updateDocument: "events/updateDocument"
    }),
    submitForm() {
      this.$v.$touch();
      this.loading = true;

      if (!this.$v.$invalid) {
        if (this.editing) {
          const payload = {
            eventId: this.event.id,
            documentId: this.document.id,
            file: this.form.file,
            dto: {
              category: this.form.category,
              description: this.form.description
            }
          };

          this.updateDocument(payload)
            .then(response => {
              this.loading = false;

              this.button.icon = "mdi-check";
              this.button.text = null;

              setTimeout(() => {
                this.button.icon = null;
                this.button.text = this.$i18n.t("forms.document.submit");
              }, 2000);
            })
            .catch(response => {
              this.loading = false;

              this.button.icon = "mdi-close";
              this.button.text = null;

              setTimeout(() => {
                this.button.icon = null;
                this.button.text = this.$i18n.t("forms.document.submit");
              }, 2000);
            });
        } else {
          if (this.form.category === "HEAT_RESULT" && this.heat !== null) {
            const payload = {
              eventId: this.event.id,
              heatId: this.heat.id,
              file: this.form.file,
              dto: {
                category: this.form.category,
                description: this.form.description
              }
            };

            this.createHeatDocument(payload)
              .then(response => {
                this.loading = false;

                this.button.icon = "mdi-check";
                this.button.text = null;

                this.resetForm();

                setTimeout(() => {
                  this.button.icon = null;
                  this.button.text = this.$i18n.t("forms.document.submit");
                }, 2000);
              })
              .catch(response => {
                this.loading = false;

                this.button.icon = "mdi-close";
                this.button.text = null;

                setTimeout(() => {
                  this.button.icon = null;
                  this.button.text = this.$i18n.t("forms.document.submit");
                }, 2000);
              });
          } else if (this.event) {
            const payload = {
              eventId: this.event.id,
              file: this.form.file,
              dto: {
                category: this.form.category,
                description: this.form.description
              }
            };

            this.createEventDocument(payload)
              .then(response => {
                this.loading = false;

                this.button.icon = "mdi-check";
                this.button.text = null;

                this.resetForm();

                setTimeout(() => {
                  this.button.icon = null;
                  this.button.text = this.$i18n.t("forms.document.submit");
                }, 2000);
              })
              .catch(response => {
                this.loading = false;

                this.button.icon = "mdi-close";
                this.button.text = null;

                setTimeout(() => {
                  this.button.icon = null;
                  this.button.text = this.$i18n.t("forms.document.submit");
                }, 2000);
              });
          }
        }
      } else {
        this.loading = false;
      }
    },
    initForm() {
      this.resetForm();
      this.form.category = this.document.category;
      this.form.description = this.document.description;
    },
    resetForm() {
      this.$v.form.$reset();
      this.form.description = null;
      this.form.file = null;
    },
    iconColor(icon) {
      switch (String(icon)) {
        case "mdi-check":
          return "success";
        case "mdi-close":
          return "error";
        default:
          return "primary";
      }
    }
  }
};
</script>
