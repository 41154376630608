<template>
  <v-dialog v-model="show" width="500" @click:outside="$emit('closed')">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          {{ $t("events.document.edit") }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <DocumentForm
        ref="documentForm"
        :document="document"
        :event="event"
        :heat="heat"
        :editing="true"
      ></DocumentForm>
    </v-card>
  </v-dialog>
</template>

<script>
import DocumentForm from "@/components/DocumentForm";
import { mapActions } from "vuex";

export default {
  name: "DocumentEditDialog",
  components: {
    DocumentForm
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    event: {
      type: Object,
      required: true
    },
    document: {
      type: Object,
      default: null
    },
    heat: {
      type: Object,
      default: null
    }
  },
  watch: {
    show(newShow, oldShow) {
      if (newShow) {
        setTimeout(() => this.$refs.documentForm.initForm());
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closed");
    }
  }
};
</script>
