<template>
  <v-card flat>
    <div v-if="heats && heats.length > 0" class="mt-4">
      <v-slide-group
        v-model="selectedHeat"
        mandatory
        center-active
        show-arrows
        class="mx-1"
      >
        <v-slide-item
          v-for="(heat, heatIndex) in heats"
          :key="heat.id"
          :value="heatIndex"
          v-slot:default="{ active, toggle }"
        >
          <v-card :color="active ? 'primary' : ''" class="ma-2" @click="toggle">
            <v-card-title class="pa-2" :class="{ 'white--text': active }">
              {{
                heatIndex === 0 && isRegularity && isTargetTime
                  ? $t("competitors.tables.targetTime")
                  : heat.name
              }}
            </v-card-title>
            <v-card-subtitle class="pa-2" :class="{ 'white--text': active }">
              {{ $d(heat.startTime, "shortDateTime") }}
            </v-card-subtitle>
            <v-card-text
              class="pt-0 px-2 pb-2 text-uppercase"
              :class="{ 'white--text': active }"
            >
              {{ $t(`events.state.${heat.state.toLowerCase()}`) }}
            </v-card-text>
          </v-card>
        </v-slide-item>
        <v-slide-item
          v-if="rankedHeats.length > 1"
          key="TOTAL"
          value="TOTAL"
          v-slot:default="{ active, toggle }"
        >
          <v-card
            :color="active ? 'primary' : ''"
            class="d-flex ma-2"
            :class="{
              'grey lighten-3': overallRankingEntries.length === 0 && !active
            }"
            @click="toggle"
          >
            <v-card-title
              class="pa-2"
              :class="{
                'grey--text text--darken-1':
                  overallRankingEntries.length === 0 && !active,
                'white--text': active
              }"
            >
              {{ $t("events.total") }}
            </v-card-title>
          </v-card>
        </v-slide-item>
      </v-slide-group>

      <v-card flat>
        <v-card-title class="mx-0 px-3">
          {{ $t("events.documents") }}
        </v-card-title>

        <DocumentList
          :title="activeDocumentCategory.text"
          :category="activeDocumentCategory"
          :event="event"
          :heat="activeHeat"
          :documents="filteredDocuments(activeDocumentCategory.value)"
          @edit-document="openDocumentEditDialog"
        />
      </v-card>

      <DocumentEditDialog
        :show="showDocumentEditDialog"
        :document="documentEdit"
        :categories="documentCategories"
        :event="event"
        :heat="activeHeat"
        @closed="showDocumentEditDialog = false"
      />

      <div v-if="activeHeat">
        <div class="d-flex justify-end flex-column flex-md-row ma-2 ma-md-4">
          <PrintStartList
            :event="event"
            :heat="activeHeat"
            :heatIndex="selectedHeat"
          />
        </div>

        <HeatStartList
          :event="event"
          :heat="activeHeat"
          :heatIndex="selectedHeat"
          @open-edit-action="openCompetitorDialog"
          @edit-competitor-comment="openCompetitorCommentDialog"
        />

        <div class="d-flex justify-end flex-column flex-md-row ma-2 ma-md-4">
          <ResultsExportMenu :event="event" />

          <PrintRanking :event="event" :heat="activeHeat" />
        </div>

        <HeatRanking
          :event="event"
          :heat="activeHeat"
          @open-edit-action="openCompetitorDialog"
          @open-edit-run-action="openCompetitorRunEditDialog"
          @referenceUpdate="handleReferenceUpdate"
          @edit-competitor-comment="openCompetitorCommentDialog"
        />
      </div>

      <div v-else>
        <div class="d-flex justify-end flex-column flex-md-row ma-2 ma-md-4">
          <ResultsExportMenu :event="event" />

          <PrintRanking :event="event" />
        </div>

        <EventRanking
          :event="event"
          :heat="activeHeat"
          @open-edit-action="openCompetitorDialog"
        />
      </div>

      <CompetitorDialog ref="competitorDialog" :event="event" />

      <CompetitorRunEditDialog
        v-if="isOwner"
        ref="competitorRunEdit"
        :event="event"
      />

      <CompetitorCommentEdit
        ref="competitorCommentEdit"
        :event="event"
        :heat="activeHeat"
      />
    </div>
    <v-card-subtitle v-else>
      {{ $t("events.noHeats") }}
    </v-card-subtitle>
  </v-card>
</template>

<script>
/* eslint-disable */

import { mapState } from "vuex";
import TabHeatsImport from "@/components/TabHeatsImport";
import ResultsExportMenu from "@/components/ResultsExportMenu";
import PrintRanking from "@/components/PrintRanking";
import HeatRanking from "@/components/HeatRanking";
import EventRanking from "@/components/EventRanking";
import CompetitorRunEditDialog from "@/components/CompetitorRunEditDialog";
import CompetitorDialog from "@/components/CompetitorDialog";
import DocumentList from "@/components/DocumentList";
import DocumentEditDialog from "@/components/DocumentEditDialog";
import HeatStartList from "@/components/HeatStartList";
import PrintStartList from "@/components/PrintStartList";
import CompetitorCommentEdit from "@/components/CompetitorCommentEdit";

export default {
  name: "TabHeats",
  components: {
    TabHeatsImport,
    DocumentList,
    DocumentEditDialog,
    ResultsExportMenu,
    PrintRanking,
    HeatRanking,
    EventRanking,
    CompetitorRunEditDialog,
    CompetitorDialog,
    HeatStartList,
    PrintStartList,
    CompetitorCommentEdit
  },
  data() {
    return {
      selectedHeat: null,
      documentCategories: [
        {
          text: this.$i18n.t("events.documentCategories.heatResults"),
          value: "HEAT_RESULT"
        },
        {
          text: this.$i18n.t("events.documentCategories.overallResults"),
          value: "OVERALL_RESULT"
        }
      ],
      showDocumentEditDialog: false,
      documentEdit: null
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      event: state => state.events.selectedItem,
      heats: state => state.events.selectedItem.heats
    }),
    rankedHeats() {
      return this.heats?.filter(el => !el.excludeFromOverallRanking) ?? [];
    },
    overallRankingEntries() {
      return (
        this.event?.overallRanking?.entries?.filter(
          el => el.state === "FINISHED"
        ) ?? []
      );
    },
    activeHeat() {
      if (this.selectedHeat !== null && this.selectedHeat !== "TOTAL") {
        return this.heats[this.selectedHeat];
      }

      return null;
    },
    isOwner() {
      if (this.user) {
        return this.user.id === this.event.ownerId;
      }
      return false;
    },
    activeDocumentCategory() {
      if (this.activeHeat) {
        return this.documentCategories[0];
      }

      return this.documentCategories[1];
    },
    isRegularity() {
      return this.event.setup.regularitySettings.enabled;
    },
    isTargetTime() {
      return this.event.setup.regularitySettings.type === "TARGET_TIME";
    }
  },
  watch: {
    selectedHeat() {
      this.page = 1;
    }
  },
  methods: {
    filteredDocuments(category) {
      if (this.event && this.event.documents && category) {
        if (category === "HEAT_RESULT" && this.activeHeat) {
          return this.event.documents
            .filter(
              document =>
                document.category === category &&
                document.heatId === this.activeHeat.id
            )
            .sort((a, b) => a.modified - b.modified);
        }
        return this.event.documents
          .filter(document => document.category === category)
          .sort((a, b) => a.modified - b.modified);
      }

      return null;
    },
    openCompetitorDialog(competitor) {
      this.$refs.competitorDialog.openDialog(competitor);
    },
    openCompetitorRunEditDialog(heatId, competitorId) {
      this.$refs.competitorRunEdit.openDialog(heatId, competitorId);
    },
    openDocumentEditDialog(document) {
      this.documentEdit = document;
      this.showDocumentEditDialog = true;
    },
    openCompetitorCommentDialog(competitorId) {
      this.$refs.competitorCommentEdit.openDialog(competitorId);
    },
    handleReferenceUpdate(success, response) {
      this.$emit("referenceUpdate", success, response);
    }
  }
};
</script>
