<template>
  <div>
    <v-btn
      @click="printHandler"
      class="ma-1"
      color="primary"
      small
      :disabled="isDisabled"
    >
      <v-icon>mdi-printer</v-icon>
    </v-btn>

    <div v-show="false" :id="printId">
      <div
        class="v-application v-application--is-ltr theme--light"
        style="font-family: 'Roboto'"
      >
        <div class="flex-grow-1">
          <div>
            <div class="d-flex justify-space-between align-center">
              <h1>{{ event.name }}</h1>
              <p v-if="event.start === event.end" style="font-weight: bold">
                {{ $d(new Date(event.start * 1000), "shortDate") }}
              </p>
              <p v-else style="font-weight: bold">
                {{ $d(new Date(event.start * 1000), "shortDate") }} -
                {{ $d(new Date(event.end * 1000), "shortDate") }}
              </p>
            </div>
            <div class="d-flex justify-space-between align-center">
              <p class="text-right">
                {{ $t("organizer.organizedBy") }}
                {{ event.organizer.organizerName }}
              </p>
              <p>{{ event.place }} ({{ event.country }})</p>
            </div>
            <h2>{{ $t("events.startList") }}: {{ heat.name }}</h2>
          </div>
          <HeatStartList
            v-if="heat"
            :event="event"
            :heat="heat"
            :heatIndex="heatIndex"
            print
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeatStartList from "@/components/HeatStartList";

export default {
  name: "PrintStartList",
  components: {
    HeatStartList
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    heat: {
      type: Object,
      default: null
    },
    heatIndex: {
      type: Number,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    printId() {
      return "heatStartList";
    },
    isDisabled() {
      return this.heat ? this.heat.competitors.length === 0 : true;
    }
  },
  methods: {
    printHandler() {
      this.$htmlToPaper(this.printId);
    }
  }
};
</script>
