<template>
  <v-dialog v-model="show" width="500" @click:outside="closeDialog">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          {{ $t("forms.heatImport.title") }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-container class="px-6 py-0">
        <v-row dense>
          <v-col cols="12">
            <v-radio-group
              :label="$t('forms.heatImport.from')"
              v-model="form.list"
            >
              <v-radio :label="$t(lists[0].text)" :value="lists[0].value" />

              <v-radio v-if="prevHeat" :value="lists[1].value">
                <template v-slot:label>
                  {{ $t(lists[1].text, { heat: prevHeat.name }) }}
                </template>
              </v-radio>

              <v-radio v-if="prevHeat" :value="lists[2].value">
                <template v-slot:label>
                  {{ $t(lists[2].text, { heat: prevHeat.name }) }}
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="form.list === 'COMPETITOR_LIST'" class="px-6 py-0">
        <v-row>
          <v-col v-if="validCompetitorList.length > 0" cols="12">
            <v-alert dense outlined type="info">
              {{
                $t("forms.heatImport.amount", {
                  amount: validCompetitorList.length,
                  count: competitorList.length
                })
              }}
            </v-alert>
          </v-col>
          <v-col v-else cols="12">
            <v-alert dense outlined type="warning">
              {{ $t("forms.heatImport.noCompetitors") }}
            </v-alert>
          </v-col>
        </v-row>
      </v-container>

      <v-container
        v-if="form.list === 'RANKING_LIST' && prevHeat"
        class="px-6 pt-0 pb-4"
      >
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              :label="$t('forms.heatImport.count')"
              v-model.number="form.items.count"
              type="number"
              min="1"
              :max="prevHeat.ranking.entries.length"
              @input="checkCutting"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-checkbox
              :label="$t('forms.heatImport.enableCutting')"
              v-model="form.items.cutting"
              :disabled="form.items.count <= 0"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>

      <v-card-actions class="d-flex justify-space-between">
        <v-btn depressed @click="closeDialog">
          {{ $t("forms.heatImport.cancel") }}
        </v-btn>

        <v-btn color="primary" @click="importCompetitors">
          {{ $t("forms.heatImport.submit") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */

import { mapState, mapGetters } from "vuex";

export default {
  name: "TabHeatsImport",
  props: {
    event: {
      type: Object,
      required: true
    },
    prevHeat: {
      type: Object,
      default: null
    },
    heat: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      show: false,
      lists: [
        {
          text: "forms.heatImport.lists.competitor",
          value: "COMPETITOR_LIST"
        },
        {
          text: "forms.heatImport.lists.start",
          value: "START_LIST"
        },
        {
          text: "forms.heatImport.lists.ranking",
          value: "RANKING_LIST"
        }
      ],
      form: {
        list: "COMPETITOR_LIST",
        items: {
          cutting: true,
          count: 30
        }
      }
    };
  },
  computed: {
    ...mapState({
      competitors: state => state.events.selectedItem.competitors
    }),
    ...mapGetters({
      getCompetitorById: "events/getCompetitorById"
    }),
    competitorList() {
      return this.event.competitors;
    },
    validCompetitorList() {
      return this.event.competitors.filter(
        competitor => competitor.startNumber > 0
      );
    }
  },
  methods: {
    openDialog() {
      this.show = true;
      this.initForm();
    },
    closeDialog() {
      this.show = false;
    },
    initForm() {
      this.form.list = "COMPETITOR_LIST";
      this.form.items.cutting = true;
      this.form.items.count = this.prevHeat
        ? Math.min(this.prevHeat.ranking.entries.length, 30)
        : null;
    },
    getDateTime(timestamp) {
      return this.$i18n.d(timestamp, "shortDateTime");
    },
    checkCutting(count) {
      if (count <= 0) {
        this.form.items.cutting = false;
      }
    },
    importCompetitors() {
      let competitors = [];

      switch (this.form.list) {
        case "COMPETITOR_LIST":
          competitors = this.event.competitors
            .filter(competitor => competitor.startNumber > 0)
            .sort((a, b) => a.startNumber - b.startNumber);
          break;
        case "START_LIST":
          competitors = this.prevHeat.competitors
            .reduce((re, el) => {
              const competitor = this.getCompetitorById(el);
              if (competitor) {
                re.push(competitor);
              }
              return re;
            }, [])
            .filter(competitor => competitor.startNumber > 0);
          break;
        case "RANKING_LIST":
          if (
            this.form.items.count > 0 &&
            this.form.items.count < this.prevHeat.ranking.entries.length
          ) {
            let flipIndex = this.form.items.count - 1;
            while (
              this.prevHeat.ranking.entries[flipIndex + 1] &&
              this.prevHeat.ranking.entries[flipIndex].rank ===
                this.prevHeat.ranking.entries[flipIndex + 1].rank
            ) {
              flipIndex += 1;
            }

            competitors = this.prevHeat.ranking.entries
              .slice(0, flipIndex + 1)
              .reverse()
              .concat(this.prevHeat.ranking.entries.slice(flipIndex + 1))
              .reduce((re, el) => {
                const competitor = this.getCompetitorById(el.competitorId);
                if (competitor) {
                  re.push(competitor);
                }
                return re;
              }, [])
              .filter(competitor => competitor.startNumber > 0);

            if (this.form.items.cutting) {
              competitors = competitors.slice(0, flipIndex + 1);
            }
          } else {
            competitors = this.prevHeat.ranking.entries.reduce((re, el) => {
              const competitor = this.getCompetitorById(el.competitorId);
              if (competitor) {
                re.push(competitor);
              }
              return re;
            }, []);
          }
          break;
        default:
          break;
      }

      this.$emit("competitorsImported", competitors);
      this.closeDialog();
    }
  }
};
</script>
