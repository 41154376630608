<template>
  <div>
    <v-container v-if="!print" :fluid="$vuetify.breakpoint.lgAndDown">
      <v-row>
        <v-col cols="12" sm="6">
          <v-card-title class="pa-0 py-md-4">
            {{ $t("events.startList") }}
            <TableFilter :event="event" />
          </v-card-title>
        </v-col>

        <v-col
          :cols="isOwner ? '10' : '12'"
          sm="3"
          :class="isOwner ? 'offset-sm-2' : 'offset-sm-3'"
        >
          <v-text-field
            :label="$t('events.search')"
            v-model="search"
            append-icon="mdi-magnify"
            single-line
            clearable
          ></v-text-field>
        </v-col>

        <v-col
          v-if="isOwner"
          cols="2"
          sm="1"
          class="d-flex justify-center align-center"
        >
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </template>

            <v-list flat>
              <v-subheader>
                {{ $t("events.heats.actions.competitors") }}
              </v-subheader>
              <v-list-item-group>
                <v-list-item
                  :disabled="loading"
                  @click="openStartListImportDialog"
                >
                  <v-list-item-content>
                    {{ $t("forms.heatImport.action") }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  :disabled="loading"
                  @click="removeCompetitorsFromHeat"
                >
                  <v-list-item-content>
                    {{ $t("events.heats.actions.delete.all.label") }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  :disabled="loading || saveDisabledStatus"
                  @click="saveStartList"
                >
                  <v-list-item-content
                    :class="
                      !loading && !saveDisabledStatus ? 'success--text' : ''
                    "
                  >
                    {{ $t("forms.heatImport.save") }}
                  </v-list-item-content>
                  <v-list-item-icon v-if="loading">
                    <v-progress-circular
                      :size="15"
                      :width="2"
                      indeterminate
                    ></v-progress-circular>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :headers="
        print ? startHeaders : isOwner ? startHeadersOwner : startHeaders
      "
      :items="competitors"
      :sort-by.sync="startListSort"
      :search="search"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      mobile-breakpoint="0"
      must-sort
      @update:options="sortCompetitors"
      dense
      :disable-sort="print"
      :disable-filtering="print"
      :disable-pagination="print"
      :hide-default-footer="print"
      :class="printClass"
    >
      <template v-slot:item.startNumber="{ item }">
        <a @click="$emit('open-edit-action', item)">
          {{ item.startNumber }}
        </a>
      </template>

      <template v-slot:item.userData.name="{ item }">
        <span style="white-space: nowrap">
          {{ getCompetitorName(item) }}
        </span>
      </template>

      <template v-slot:item.userData.nation="{ item }">
        <span v-if="item.userData.nation && print">{{
          item.userData.nation
        }}</span>
        <CountryFlag
          v-else-if="item.userData.nation"
          :country="getNationById(item.userData.nation)"
          :size="20"
        />
      </template>

      <template v-slot:item.userData.gender="{ item }">
        <v-icon small>
          {{ genderIcon(item.userData.gender) }}
        </v-icon>
      </template>

      <template v-slot:item.userData.dateOfBirth="{ item }">
        {{
          item.userData.dateOfBirth
            ? isNaN(new Date(item.userData.dateOfBirth))
              ? item.userData.dateOfBirth
              : $d(new Date(item.userData.dateOfBirth).getTime(), "shortDate")
            : ""
        }}
      </template>

      <template v-slot:item.startTime.timestamp="{ item }">
        {{
          formatTimestamp_100ns(
            item.startTime.timestamp,
            item.startTime.offset,
            false,
            precision
          )
        }}
      </template>

      <template v-slot:item.note="{ item }">
        <v-tooltip v-if="item.note || isOwner" top>
          <template v-slot:activator="{ on }">
            <span>
              <v-btn
                v-if="isOwner"
                icon
                @click="$emit('edit-competitor-comment', item.id)"
              >
                <v-icon :color="item.note ? 'primary' : 'default'" v-on="on">
                  mdi-note-edit-outline
                </v-icon>
              </v-btn>
              <v-icon v-else color="primary" v-on="on">
                mdi-note-outline
              </v-icon>
            </span>
          </template>
          <span>{{ item.note ? item.note : "-" }}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.actions="{ item, index }">
        <div class="d-flex justify-end">
          <v-btn
            icon
            small
            :disabled="calcTotalIndex(index) === 0"
            @click="moveItemUp(calcTotalIndex(index))"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>

          <v-btn
            icon
            small
            @click="removeCompetitorFromHeat(item.id)"
            :disabled="!isCompetitorDeletable(item.id)"
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>

          <v-btn
            icon
            small
            :disabled="calcTotalIndex(index) === competitors.length - 1"
            @click="moveItemDown(calcTotalIndex(index))"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <TabHeatsImport
      ref="startListImportDialog"
      :event="event"
      :heat="heat"
      :prevHeat="prevHeat"
      :loading="loading"
      :style="$vuetify.breakpoint.xsOnly ? 'width: 100%' : ''"
      @competitorsImported="importCompetitors"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import TableFilter from "@/components/TableFilter";
import CountryFlag from "@/components/CountryFlag";
import TabHeatsImport from "@/components/TabHeatsImport";
import whitelist from "@/mixins/whitelist";
import genderIcon from "@/mixins/genderIcon";
import getCompetitorName from "@/mixins/getCompetitorName";
import headers from "@/mixins/headers";
import formatTimestamp from "@/mixins/formatTimestamp";

export default {
  name: "HeatStartList",
  components: {
    TableFilter,
    CountryFlag,
    TabHeatsImport
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    heat: {
      type: Object,
      required: true
    },
    heatIndex: {
      type: Number,
      required: true
    },
    print: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      startListSort: null,
      competitorsClone: [],
      page: 1,
      itemsPerPage: 10,
      search: "",
      precisions: ["1s", "1/10s", "1/100s", "1/1000s", "1/10000s", "1/100000s"]
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    ...mapGetters({
      getCompetitorById: "events/getCompetitorById",
      getNationById: "nations/getItemById",
      getFieldPreferenceById: "settings/getFieldPreferenceById"
    }),
    precision() {
      const precision = this.precisions.findIndex(
        el => el === this.event.setup.precision
      );

      return precision >= 0 ? precision : 2;
    },
    printClass() {
      if (this.startHeaders.length < 15) {
        return "";
      }
      if (this.startHeaders.length < 17) {
        return "print-large";
      }
      if (this.startHeaders.length < 19) {
        return "print-medium";
      }
      return "print-small";
    },
    isOwner() {
      if (this.user) {
        return this.user.id === this.event.ownerId;
      }
      return false;
    },
    startHeaders() {
      const headers = Array.from(this.headers());
      headers.unshift({
        text: this.$i18n.t("competitors.tables.startOrder"),
        value: "startOrder",
        align: "right",
        width: "1%"
      });
      headers.push({
        text: this.$i18n.t("competitors.tables.startTime"),
        value: "startTime.timestamp",
        sort: (a, b) => {
          if (a === null) {
            return 1;
          }
          if (b === null) {
            return -1;
          }
          return a - b;
        }
      });
      if (this.heat.competitorInformation.length > 0 || this.isOwner) {
        headers.push({
          text: this.$i18n.t("competitors.tables.note"),
          value: "note",
          sortable: false
        });
      }
      return headers;
    },
    startHeadersOwner() {
      const headers = Array.from(this.startHeaders);
      headers.push({
        value: "actions",
        sortable: false,
        width: "1%"
      });
      return headers;
    },
    saveDisabledStatus() {
      return (
        JSON.stringify(this.heat.competitors) ===
        JSON.stringify(this.competitors.map(competitor => competitor.id))
      );
    },
    competitors() {
      return this.competitorsClone[this.heatIndex].map(competitor => {
        const competitorInformation = this.heat.competitorInformation.find(
          el => el.competitorId === competitor.id
        );
        const heatCycleEntry = this.heat.heatCycle.entries.find(
          el => el.competitorId === competitor.id
        );
        return {
          ...competitor,
          startTime: {
            timestamp:
              heatCycleEntry && heatCycleEntry.data.sections["START"]
                ? heatCycleEntry.data.sections["START"].timestamp_100ns
                : null,
            offset:
              heatCycleEntry && heatCycleEntry.data.sections["START"]
                ? heatCycleEntry.data.sections["START"].timeOffset_m
                : null
          },
          note: competitorInformation ? competitorInformation.note : null
        };
      });
    },
    prevHeat() {
      if (!this.heatIndex || this.heatIndex === 0) return null;
      return this.event.heats[this.heatIndex - 1];
    }
  },
  watch: {
    heat() {
      this.page = 1;
    }
  },
  mixins: [whitelist, genderIcon, getCompetitorName, headers, formatTimestamp],
  methods: {
    ...mapMutations({
      setSelectedItemHeatCompetitors: "events/setSelectedItemHeatCompetitors"
    }),
    ...mapActions({
      addCompetitorsToItemHeat: "events/addCompetitorsToItemHeat"
    }),
    calcTotalIndex(index) {
      if (this.itemsPerPage === -1) {
        return index;
      }
      return index + this.itemsPerPage * (this.page - 1);
    },
    sortCompetitors({ sortBy, sortDesc }) {
      let competitors = this.competitors;

      if (sortBy.length > 0 && sortDesc.length > 0) {
        const sortKeys = sortBy[0].split(".");

        competitors = competitors.sort((a, b) => {
          let sortA =
            sortKeys.length === 1
              ? a[sortKeys[0]]
              : a[sortKeys[0]][sortKeys[1]];
          let sortB =
            sortKeys.length === 1
              ? b[sortKeys[0]]
              : b[sortKeys[0]][sortKeys[1]];

          if (!isNaN(sortA)) {
            sortA = parseInt(sortA);
          }

          if (!isNaN(sortB)) {
            sortB = parseInt(sortB);
          }

          if (sortDesc[0]) {
            if (sortA < sortB) return 1;
            if (sortA > sortB) return -1;
            return 0;
          } else {
            if (sortA < sortB) return -1;
            if (sortA > sortB) return 1;
            return 0;
          }
        });
      }

      this.setCompetitorsCloneOfHeat(competitors);
    },
    importCompetitors(competitors) {
      this.setCompetitorsCloneOfHeat(competitors);
      this.saveStartList();
    },
    setCompetitorsClone() {
      this.event.heats.map((heat, index) => {
        const competitorsClone = Array.from(heat.competitors).reduce(
          (re, competitorId, index) => {
            const competitor = this.getCompetitorById(competitorId);

            if (competitor) {
              re.push({
                startOrder: index + 1,
                ...competitor
              });
            }

            return re;
          },
          []
        );

        Vue.set(this.competitorsClone, index, competitorsClone);
      });
    },
    setCompetitorsCloneOfHeat(competitors) {
      Vue.set(this.competitorsClone, this.heatIndex, competitors);
    },
    removeCompetitorFromHeat(competitorId) {
      const competitorIndex = this.competitors.findIndex(
        competitor => competitor.id === competitorId
      );

      Vue.delete(this.competitorsClone[this.heatIndex], competitorIndex);
    },
    removeCompetitorsFromHeat() {
      const competitorsCount = this.competitors.length;
      const deletableCompetitorsCount = this.competitors.filter(el =>
        this.isCompetitorDeletable(el.id)
      ).length;

      if (
        confirm(
          this.$i18n.t("events.heats.actions.delete.all.confirmation", {
            deletableCompetitors: deletableCompetitorsCount,
            competitors: competitorsCount
          })
        )
      ) {
        const nonDeletableCompetitors = this.competitors.filter(
          el => !this.isCompetitorDeletable(el.id)
        );
        this.setCompetitorsCloneOfHeat(nonDeletableCompetitors);
        this.saveStartList();
      }
    },
    saveStartList() {
      this.loading = true;

      const payload = {
        eventId: this.event.id,
        heatId: this.heat.id,
        data: this.competitors.map(competitor => competitor.id)
      };

      this.addCompetitorsToItemHeat(payload)
        .then(() => {
          const competitors = this.competitors.map((competitor, index) => ({
            startOrder: index + 1,
            ...competitor
          }));

          this.setCompetitorsClone(competitors);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    openStartListImportDialog() {
      this.$refs.startListImportDialog.openDialog();
    },
    isCompetitorDeletable(competitorId) {
      for (const entry of this.heat.heatCycle.entries) {
        if (entry.competitorId === competitorId) {
          return false;
        }
      }

      return true;
    },
    moveItemUp(index) {
      const competitors = this.moveItem(
        this.competitorsClone[this.heatIndex],
        index,
        index - 1
      );
      this.setCompetitorsCloneOfHeat(competitors);
    },
    moveItemDown(index) {
      const competitors = this.moveItem(
        this.competitorsClone[this.heatIndex],
        index,
        index + 1
      );
      this.setCompetitorsCloneOfHeat(competitors);
    },
    moveItem(items, oldIndex, newIndex) {
      const itemRemovedArray = [
        ...items.slice(0, oldIndex),
        ...items.slice(oldIndex + 1, items.length)
      ];
      return [
        ...itemRemovedArray.slice(0, newIndex),
        items[oldIndex],
        ...itemRemovedArray.slice(newIndex, itemRemovedArray.length)
      ];
    }
  },
  mounted() {
    this.$store.subscribe(mutation => {
      const whitelist = [
        "events/setSelectedItem",
        "events/setSelectedItemHeat",
        "events/updateCompetitorOfSelectedItem"
      ];

      if (whitelist.includes(mutation.type)) {
        this.startListSort = null;
        this.setCompetitorsClone();
      }
    });
  },
  created() {
    this.setCompetitorsClone();
  }
};
</script>
<style scoped>
.v-data-table /deep/ th[role="columnheader"] {
  white-space: nowrap;
}
.v-data-table--dense /deep/ tbody tr:nth-child(even) {
  background: #f7f7f7;
}
.v-data-table--dense /deep/ thead > tr > th,
.v-data-table--dense /deep/ tbody > tr > td {
  padding: 0 10px !important;
}
</style>
