<template>
  <div class="mx-8 mb-3">
    <div class="d-flex align-center">
      <a :href="filePath" :title="document.description" target="_blank">
        {{ document.description }}
      </a>

      <v-btn
        v-if="isOwner"
        @click="$emit('edit-document', document)"
        icon
        small
        class="ml-4 ml-sm-2"
      >
        <v-icon :small="$vuetify.breakpoint.smAndUp">mdi-pencil</v-icon>
      </v-btn>

      <v-btn
        v-if="isOwner"
        @click="removeHandler"
        :color="iconColor(remove.icon)"
        :loading="remove.loading"
        small
        icon
        class="ml-2 ml-sm-0"
      >
        <v-icon :small="$vuetify.breakpoint.smAndUp">
          {{ remove.icon }}
        </v-icon>
      </v-btn>
    </div>

    <p class="caption ma-0">
      {{ $t("events.document.lastEdited") }}:
      {{ $d(document.modified, "shortDateTime") }}
    </p>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Document",
  props: {
    document: {
      type: Object,
      required: true
    },
    event: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_BASE_URL,
      remove: {
        loading: false,
        icon: "mdi-delete"
      }
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    filePath() {
      return `${this.baseURL}/${this.document.path}`;
    },
    isOwner() {
      if (this.user) {
        return this.user.id === this.event.ownerId;
      }
      return false;
    }
  },
  methods: {
    ...mapActions({
      deleteDocument: "events/deleteDocument"
    }),
    removeHandler() {
      this.remove.loading = true;

      const payload = {
        eventId: this.document.sportEventId,
        documentId: this.document.id
      };

      if (confirm("Are you sure you want to delete this document?")) {
        this.deleteDocument(payload)
          .then(response => {
            this.remove.icon = "mdi-check";
            this.remove.loading = false;

            setTimeout(() => {
              this.remove.icon = "mdi-delete";
            }, 2000);
          })
          .catch(response => {
            this.remove.icon = "mdi-close";
            this.remove.loading = false;

            setTimeout(() => {
              this.remove.icon = "mdi-delete";
            }, 2000);
          });
      } else {
        this.remove.loading = false;
      }
    },
    iconColor(icon) {
      switch (String(icon)) {
        case "mdi-check":
          return "success";
        case "mdi-close":
          return "error";
        default:
          return "";
      }
    }
  }
};
</script>
