<template>
  <v-card flat>
    <Document
      v-for="document in documents"
      :key="document.id"
      :document="document"
      :event="event"
      @edit-document="editHandler"
    />

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-dialog v-if="isOwner" v-model="showAddDocumentDialog" width="500">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" text v-on="on">
            {{ $t("forms.document.action") }}
          </v-btn>
        </template>

        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>
              {{ $t("events.document.add") }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="showAddDocumentDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <DocumentForm
            :category="category"
            :event="event"
            :heat="heat"
          ></DocumentForm>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import DocumentForm from "@/components/DocumentForm";
import Document from "@/components/Document";

export default {
  name: "DocumentList",
  components: {
    DocumentForm,
    Document
  },
  props: {
    category: {
      type: Object,
      required: true
    },
    event: {
      type: Object,
      required: true
    },
    heat: {
      type: Object,
      default: null
    },
    documents: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      showAddDocumentDialog: false
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    isOwner() {
      if (this.user) {
        return this.user.id === this.event.ownerId;
      }
      return false;
    }
  },
  methods: {
    editHandler(document) {
      this.$emit("edit-document", document);
    }
  }
};
</script>
